import React,{useState,useEffect} from "react";
import img2 from '../../assets/2.png';
import img1 from '../../assets/1.png';
import {NavLink} from 'react-router-dom';
import Slide from 'react-reveal/Slide';
import './index.css';
const NavBar = (props) =>{
    const [nav,setNav] = useState(true);
    const [img,setImg] = useState(true);
    const changeImg = () =>{
      setImg(false);
  }
  const defaultImg = () =>{
      setImg(true);
  }
    const scrollHandler = ()=>{
      const position = window.pageYOffset;
      setNav(true);
      if(parseInt(position) > 35){
  setNav(false);
      }
  }
  useEffect(() =>{
    window.addEventListener('scroll', scrollHandler, { passive: true });
  
    return () => {
        window.removeEventListener('scroll', scrollHandler);
    };
  },[])
    return(
        <Slide top>
        <nav className="front-face-1">
        <div className={nav ? "front-face-2" : "front-face-2 front-face-3"} id="nav-bar">
        <div onClick={props.home}>
        {img ? (
    <img src={img1} alt="img2" className="f-img f-img-1" onMouseEnter={changeImg} />
        ):(
        <img src={img2} alt="img1" className="f-img f-img-2" onMouseLeave={defaultImg}  />
        )}
        </div>
    <div className="nav-style">
        <div className="nav-style-1" >
        <NavLink to="/" className="nav-link" id="#skills" onClick={props.skills}><i class="fas fa-chart-pie"></i> <span>Skills</span></NavLink>
        </div>
        <div className="nav-style-1" >
        <NavLink to="/" className="nav-link" id="#projects" onClick={props.projects}><i class="fas fa-code"></i> <span>Projects</span></NavLink>
        </div>
        <div className="nav-style-1" >
        <NavLink to="/" className="nav-link" id="#contact" onClick={props.contact}><i class="fas fa-envelope"></i> <span>Contact</span></NavLink>
        </div>
    </div>
        </div>
    </nav>
    </Slide>
    )
}
export default NavBar;