import React from 'react';
import github from '../../assets/github.png';
import fb from '../../assets/fb.png';
import insta from '../../assets/insta.png';
import twitter from '../../assets/twitter.png';
import linkdin from '../../assets/linkdln.png';
const IconPack = () =>{
    return(
        <div className="icon-pack">
        <a href="https://github.com/Rajan2020" target="_blank"><img src={github} alt="githubIcon" /></a>
      <a href="https://www.linkedin.com/in/rajan-raj-shah-23b2ab17a/" target="_blank"><img src={linkdin} target="_blank" alt="LinkdIn" /></a>
      <a href="https://www.instagram.com/thisisrajanrajshah/?hl=en" target="_blank"><img src={insta}  alt="Insta" /></a>
      <a href="https://twitter.com/RajanRajShah1" target="_blank"><img src={twitter}  alt="Twitter" /></a>
      <a target="_blank" href="https://www.facebook.com/Rajanshah8"><img src={fb} alt="FBIcon" /></a>
</div>
    )
}
export default IconPack;