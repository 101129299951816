import React,{useEffect} from 'react';
import './index.css';
import img3 from '../../assets/Programming-amico.webp';
import IconPack from '../../Component/iconpack';
import { saveAs } from 'file-saver';
import resume from '../../assets/cv.pdf';
import Fade from 'react-reveal/Fade';
const FrontFace = () =>{
    useEffect(() => {
        let greeting = ['Namaste,','Hi,','Hola,'];
        let count = 0;
        let index = 0;
        let currentText ='';
        let letter = '';
        (function type(){
            if (count === greeting.length){
                count = 0;
            }
            currentText= greeting[count];
            letter = currentText.slice(0,++index);
            if(document.querySelector(".typing")){
                document.querySelector(".typing").textContent = letter;
                if(letter.length === currentText.length){
                    count++;
                    index = 0;
                }
            }
            setTimeout(type,400);
        })();

    },[]);
    const resumeDown =() =>{
        saveAs(resume,'RajanCV.pdf');
    }
return(
  <div className="front-face">  
      <div className="front-face-display">
          <Fade left>
      <div className="front-face-text">
          <p className="middle-text"><span className="typing"></span> I'm</p>
          <p className="large-text">Rajan Raj Shah</p>
          <p className="middle-text">a web developer, a student and a leader.</p>
<IconPack />
      <button className="btn-class" onClick={resumeDown}><i class="far fa-file-pdf"></i> Download Resume</button>
      </div>
      </Fade>
      <Fade right>
      <img src={img3} alt="vector" className="svg-img" />
      </Fade>
      </div>
  </div>)
}
export default FrontFace;