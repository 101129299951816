import React ,{useEffect,useState, useRef} from 'react';
import { Suspense } from 'react';
import './App.css';
import FrontFace from './Container/frontFace';
import {BrowserRouter} from "react-router-dom";
import AboutMe from './Container/aboutMe';
import Contact from './Container/contact';
import Navbar from './Component/Navbar';
import ProgressBar from './Component/progressBar';
const Modal = React.lazy(() => import('./Component/Modal'));
const Skills = React.lazy(() => import('./Container/skills'));
const Projects = React.lazy(() => import('./Container/projects'));
const App = () =>{
  const [imgData,setImg] = useState(null);
  const [open,setOpen] = useState(false);
  const skills = useRef(null)
  const contact = useRef(null);
  const projects = useRef(null);
  const home = useRef(null);
  useEffect(() =>{
  let handleScroll=() =>{
    if((skills.current.offsetTop - window.scrollY < window.innerHeight / 2) && (projects.current.offsetTop - window.scrollY >= window.innerHeight/2)){
      document.getElementById('#skills').classList.add('activ');
    }    
    else{
      document.getElementById('#skills').classList.remove('activ');
    }
    if((projects.current.offsetTop - window.scrollY < window.innerHeight / 2) && (contact.current.offsetTop - window.scrollY >= window.innerHeight/2)){
      document.getElementById('#projects').classList.add('activ');
    }    
    else{
      document.getElementById('#projects').classList.remove('activ');
    }
    if((contact.current.offsetTop - window.scrollY < window.innerHeight / 2)){
      document.getElementById('#contact').classList.add('activ');
    }    
    else{
      document.getElementById('#contact').classList.remove('activ');
    }
  }
  
    document.addEventListener('scroll', handleScroll);
    return () => {
        document.removeEventListener('scroll', handleScroll);
    }
  },[])
  const skillScroll = () => {
    skills.current.scrollIntoView()};   
  const projectsScroll = () => projects.current.scrollIntoView();    
  const contactScroll = () => contact.current.scrollIntoView();    
  const homeScroll = () => home.current.scrollIntoView(); 
const imgLarge =(data) =>{
    setImg(data);
    setOpen(true);
}
const closeModal = ()=>{
  setOpen(false);
}
  return(
    <div className="App">
  <BrowserRouter>
  <Navbar skills={skillScroll} projects={projectsScroll} contact={contactScroll} home={homeScroll} />
  <Suspense fallback={<ProgressBar />}>
  <Modal img={imgData} open={open} displayclose={closeModal} /> 
  </Suspense>
  <div ref={home}>
    <FrontFace />
  </div>
    <AboutMe />
    <div ref={skills}>
    <Suspense fallback={<ProgressBar />}>
    <Skills />
    </Suspense>
    </div>
    <div ref={projects}>
      <Suspense fallback={<ProgressBar />}>
      <Projects imgLarge={imgLarge} />
      </Suspense>
    </div>
    <div ref={contact}>
    <Contact />
    </div>
    </BrowserRouter>
        </div>
  )
}
export default App;
