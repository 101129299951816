import React from 'react';
import about from '../../assets/about.webp';
import './index.css';
import Zoom from "react-reveal/Zoom";
const AboutMe = () =>{
    return(
        <div className="aboutMe">
            <Zoom>
            <img src={about} alt="about" className="svg-img" />
            </Zoom>
            <div>
            <p className="large-text-1">About Me</p>
            <Zoom>
            <p className="small-text">Currently I'm pursing Bachelor's in CSIT.<br/>Love Desiging, Developing and taking challenges.<br />I want to make beautiful things. Even if nobody cares.<br/><br/>"Design is not just what is look like and feel like. Design is how it works."<br/><br/>Playing games, gardening, and<br/> learning about stock markets and crypto market are some of my hobbies.</p>
            </Zoom>
            </div>
        </div>)
}
export default AboutMe;