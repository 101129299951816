import React,{useState} from 'react';
import './index.css';
const Query = (props) =>{
    const [data,setData] = useState('bussiness');
const setResponse= (event)=>{
    setData(event.target.value);
}
const onSubmitData= () =>{
    props.sendData(data);
}
    return(
        <div className={props.display?'modal-1':'modal-1 modal-3'}>
            <div onChange={(event) =>setResponse(event)} className="modal-2">
            <p>Your message is related to:</p>
            <div className="radio" >
            <input  name="ans" id="buss" type="radio" defaultChecked value="bussiness"/>
            <label for="buss">Career opportunity apporach</label>
            </div>
            <div className="radio">
            <input name="ans" id = "cas" type="radio" value="casual"/>
            <label for="cas">Casual apporach</label>
            </div>
            </div>
            <div style={{'display': 'flex','gap':'7px'}} >
            <button onClick={props.displayRemove} className="btn-class btn-class-error">Cancel</button>
            <button onClick={onSubmitData} className="btn-class">Submit</button>
            </div>
        </div>)
}
export default Query;