import React from "react";
import './index.css';
import IconPack from '../../Component/iconpack'
import Zoom from "react-reveal/Zoom";
import cogoToast from 'cogo-toast';
import axios from '../../axios';
import Thank from '../../Component/thank';
import Query from '../../Component/thank/query';
class Contact extends React.Component {
    state ={
        display:false,
        display2:false,
        data:null,
        name:null
    }
 formHandler=(event) =>{
        event.preventDefault();
        this.setState({display:true});
    }
    inputChange = (event) =>{
        this.setState({
            [event.target.name]:event.target.value
        })
        this.setState({display:false});
    }
    sendData=(d) =>{
        this.setState({display:false});
        this.setState({data:d});
        this.setState({name:this.state.firstName+' '+this.state.lastName});
        axios.post('/request.json',{firstName:this.state.firstName,lastName:this.state.lastName,email:this.state.email,desc:this.state.desc}).then(result =>{
      this.setState({firstName:'',lastName:'',email:'',desc:''});
      this.setState({display2:true});
      setTimeout(() =>{
          this.setState({display2:false});
        },3500)
        }).catch(err =>{
         cogoToast.error('Message not sent!');
      })
    }
    render(){
    return(
        <>
        <Zoom>
        <div className="contact">
        <div style={{'line-height':'2rem','letter-spacing':'.4px','color':'#888','fontWeight':'500'}}>
        <p className="large-text large-text-3">Connect with me</p>
            <p><i class="far fa-envelope"></i> rajan.shah2022@gmail.com</p>
            <p><i class="fas fa-map-marker-alt"></i> Chobhar,Kirtipur 44600,Nepal</p>
           <IconPack />
        </div>
                <form className="contact-1" onSubmit={(event) => this.formHandler(event)}>
                    <p className="large-text large-text-3">Send me a message!</p>
            <div className="contact-3">
                <input required name="firstName" onChange={this.inputChange} value={this.state.firstName} type="text" placeholder="First Name" />
            <input required name="lastName" onChange={this.inputChange} value={this.state.lastName} type="text" placeholder="Last Name" />
            </div>
            <input required name="email" onChange={this.inputChange} value={this.state.email} type="email" placeholder="Email" />
            <textarea required name="desc" onChange={this.inputChange} value={this.state.desc} placeholder="Your Message" rows="7"/>
            <button className="btn-class">
                Send Message
            </button>
                </form>
    </div>
    </Zoom>
    <Query sendData={this.sendData} display={this.state.display} displayRemove={() => this.setState({display:false})} />
    <Thank removeDisplay={() => this.setState({display2:false})} display={this.state.display2} purposal={this.state.data} name={this.state.name} />
    </>
    )
}
}
export default Contact;